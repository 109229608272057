<template>
  <div>
    <b-card :title="$t('portfolio.titles.portfolio')">
      <b-card-body>
        <b-overlay :show="downloadPDFloading" rounded="sm" opacity="0.95" no-fade>
        <b-row>
          <!-- valuation date -->
          <b-col cols="12" md="6" xl="2" lg="2" sm="12">
            <label class="compact_label">{{$t('portfolio.valuation_date')}} </label>
            <b-form-datepicker v-model="valuationDate" :start-weekday="1" min="2020-1-1" :date-disabled-fn="dateDisabled" placeholder="Choose a valuation date" :hide-header="true" class="compact_date_picker " />
          </b-col>
          <!-- account name -->
          <b-col cols="12" md="6" xl="3" lg="3" sm="12">
            <label class="compact_label">{{$t('portfolio.account_name')}}</label>
            <v-select class="compact_form_vue-multi-select" label="AccountName" v-model="accountNameSelected" :reduce="(val) => val" multiple :selectable="() => accountNameSelected.length < 10" :disabled="valuationDate == null" :options="optAccountNames" />
          </b-col>
          <b-col cols="12" md="6" xl="3" lg="3" sm="12"
          v-if="accountNameSelected.length > 0 && accountNameSelected.filter((e) => e.AccountName == 'SFC P&L').length > 0"
          >
                  <b-form-group
                    :label="$t('swap_future.trade_form.cost_center')"
                  >
                
                      <v-select
                        v-model="costCenterSelected"
                        :options="optCostCenters"
                        :clearable="true"
                        :placeholder="
                          $t('swap_future.trade_form.cost_center')
                        "
                        :reduce="(val) => val.CostCenter"
                        label="CostCenter"
                        ref="costCenter"
                      />
                </b-form-group>
              </b-col>
          <!-- account group -->
          <b-col cols="12" md="6" xl="2" lg="2" sm="12">
            <label class="compact_label">{{$t('portfolio.account_group')}}</label>
            <v-select class="vue_select_hight" label="AccountGroup" v-model="accountGroupSelected" :reduce="(val) => val" :disabled="valuationDate == null" :options="accountGroups" />
          </b-col>
          <!-- reporting currency -->
          <b-col cols="12" md="6" xl="2" lg="2" sm="12">
            <label class="compact_label">{{$t('portfolio.reporting_currency')}}</label>
            <b-overlay :show="currencyLoading" spinner-small rounded="sm"> <v-select class="vue_select_hight" label="reportCurrency" :clearable="false" v-model="reportCurrencySelected" :disabled="valuationDate == null" :options="currencys" /> </b-overlay>
          </b-col>
          

          <!-- switch buttons  -->
          <b-col cols="12" md="6" xl="3" lg="3" sm="12" class="d-flex justify-content-between align-items-center">
            <!-- trade date valuation -->
            <b-form-group :label="$t('portfolio.trade_date_valuation')">
              <b-form-checkbox checked="true" v-model="tradeValuation" class="custom-control-dark" name="check-button" switch>
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>

          
          <!-- display empty accounts -->
            <b-form-group :label="$t('portfolio.display_empty_accounts')">
              <b-form-checkbox checked="true" v-model="emptyAccounts" class="custom-control-dark" name="check-button" switch>
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
            <!-- future portfoli valuation -->
            <b-form-group :label="$t('portfolio.future_portfolio_valuation')">
              <b-form-checkbox checked="true" v-model="futureValuation" class="custom-control-dark" name="check-button" switch>
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
            </b-form-group>

          </b-col>

          <!-- add group modal -->
          <b-modal id="modal-addgroup" cancel-variant="outline-secondary" ref="modal" ok-title="Save" @ok="handleOk" cancel-title="Close" centered title="Add Group">
            <b-form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group>
                <b-badge v-for="item in accountNameSelected" :key="item.AccountNumber" pill variant="dark" class="mr-1 mb-1">{{ item.AccountName }}</b-badge>
              </b-form-group>

              <b-form-group>
                <label for="email">{{$t('portfolio.account_group_name')}}</label>
                <b-form-input id="accountGroupName" type="text" :state="groupNameState" v-model="accountGroupName" required placeholder="Account Group Name" />
              </b-form-group>
            </b-form>
          </b-modal>
          <!-- update group modal -->
          <b-modal id="modal-updategroup" cancel-variant="outline-secondary" ref="modal" ok-title="Save" @ok="handleOk" cancel-title="Close" centered title="Update Group">
            <b-form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group>
                <b-badge v-for="item in accountNameSelected" :key="item.AccountNumber" pill variant="dark" class="mr-1 mb-1">{{ item.AccountName }}</b-badge>
              </b-form-group>

              <b-form-group>
                <label for="email">{{$t('portfolio.account_group_name')}}</label>
                <b-form-input id="accountGroupName" type="text" readonly :state="groupNameState" v-model="accountGroupName" required placeholder="Account Group Name" />
              </b-form-group>
            </b-form>
          </b-modal>
        </b-row>

        <b-row class="mt-1 justify-content-end">
          

          <b-col  cols="6" md="6" xl="6" sm="6" class="mt-2 d-flex justify-content-end align-content-center">
            

            <!-- add group button  -->
            <b-button v-if="accountNameSelected.length > 1 && accountGroupSelected == null" v-b-modal.modal-addgroup v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="dark" class="mr-1 animate__animated animate__fadeIn" size="sm">
              {{$t('portfolio.add_group')}}
            </b-button>
            <!-- update group button -->
            <b-button v-if="accountNameSelected.length > 1 && accountGroupSelected != null && $Can('manage', 'all')" v-b-modal.modal-updategroup @click="updateGroup()" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="dark" size="sm" class="mr-1 animate__animated animate__fadeIn">
              {{$t('portfolio.update_group')}}
            </b-button>

            
            <b-button class="animate__animated animate__fadeIn" v-ripple.400="'rgba(113, 102, 240, 0.15)'"  @click="downloadPdf(portfolioDatas)" variant="outline-primary" size="sm" v-if="accountNameSelected.length > 0">
              <feather-icon icon="ArrowDownIcon" class="mr-50" />
              <span class="align-middle">{{
              $t(
                "portfolio.download_pdf"
              )
            }}</span>
            </b-button>
            
          </b-col>
        </b-row>
        <template #overlay v-if="downloadPDFloading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />
              {{
                $t(
                  "general_text.download_pdf"
                )
              }}
            </p>
          </div>
        </template>
        </b-overlay>
      </b-card-body>
    </b-card>
    
    <b-overlay :show="loading" rounded="sm" opacity="0.95" no-fade>
      <b-row class="match-height mt-2">
        <b-col cols="12" v-if="showAlertMessage">
          <b-alert variant="info" show>
            <h4 class="alert-heading">
              {{$t('portfolio.portfolio_reporting')}}
            </h4>
            <div class="alert-body">
              <span>{{$t('portfolio.fill_generate_portfolio')}}</span>
            </div>
          </b-alert>
        </b-col>

        <b-col cols="12" v-if="portfolioDatas.length == 0">
          <b-alert variant="warning" show>
            <h4 class="alert-heading">
              {{$t('portfolio.not_found')}}
            </h4>
            <div class="alert-body">
              <span>{{$t('portfolio.not_found_portfolio')}}</span>
            </div>
          </b-alert>
        </b-col>

        
        <b-col cols="12" v-else>
          <!-- ACCOUNT NAMES -->
          <b-card-actions v-if="portfolioDatas.fields && (emptyAccounts != false || portfolioDatas.currentAccounts.length > 0)" action-collapse :title="$t('portfolio.account_names') + accountNamesTitle">
            <b-row>
              <b-col cols="12">
                <b-table
                  :items="portfolioDatas.currentAccounts"
                  :fields="portfolioDatas.fields[0]"
                  :bordered="true"
                  table-variant="none"
                  :small="true"
                  :hover="true"
                  :striped="true"
                  :fixed="false"
                  headVariant="dark"
                  class="text-center compact_table"
                  :tbody-tr-class="rowClass"
                  responsive
                >
                  <!--     fields: ['SFC_Uti', 'Date', 'Related Uti', 'Price', 'Type', 'Description', 'Currency', 'Amount', 'Balance'], -->
                  <template #cell(s0)="data">
                    <div class="text-left">
                      {{ data.item.AccountName }}
                    </div>
                  </template>
                  <template #cell(s1)="data">
                    <small>
                      <div class="text-left text-nowrap">{{ data.item.Underlying }} {{ data.item.AccountType }}</div></small
                    >
                  </template>

                  <template #cell(s2)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT < 0 ? 'text-danger' : '']">
                      <small>{{ data.item.Underlying }} </small> / {{ moneyFixed(data.item.TotalAmountT) }}
                    </div>
                  </template>

                  <template #cell(s3)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT1 < 0 ? 'text-danger' : '']">
                      <small>{{ data.item.Underlying }} </small> / {{ moneyFixed(data.item.TotalAmountT1) }}
                    </div>
                  </template>
                  <template #cell(s4)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT2 < 0 ? 'text-danger' : '']">
                      <small>{{ data.item.Underlying }} </small> / {{ moneyFixed(data.item.TotalAmountT2) }}
                    </div>
                  </template>
                  <template #cell(s5)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT3 < 0 ? 'text-danger' : '']">
                      <small>{{ data.item.Underlying }} </small> / {{ moneyFixed(data.item.TotalAmountT3) }}
                    </div>
                  </template>
                  <template #cell(s6)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT4 < 0 ? 'text-danger' : '']">
                      <small>{{ data.item.Underlying }} </small> / {{ moneyFixed(data.item.TotalAmountT4) }}
                    </div>
                  </template>
                  <template #cell(s7)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountF < 0 ? 'text-danger' : '']">
                      <small>{{ data.item.Underlying }} </small> / {{ moneyFixed(data.item.TotalAmountF) }}
                    </div>
                  </template>

                  <template #cell(s8)="data">
                    <div class="text-right text-nowrap d-flex justify-content-center align-items-center">
                      <b-badge class="mr-1" variant="dark"> {{ data.item.AccountCcy ? data.item.AccountCcy : '-'  }} </b-badge>
                      <b-badge class="mr-1" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange2" variant="dark"> {{ data.item.ExchangeCcy2 ? data.item.ExchangeCcy2 : '-' }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange2" variant="success"> {{ data.item.Exchange2.toFixed(4) }} </b-badge>
                    </div>
                  </template>

                  <template slot="bottom-row" v-if="portfolioDatas.currentAccounts.length > 0">
                    <td class="table-secondary font-weight-bold text-right" v-if="multiReport == true"></td>
                    <td class="table-secondary font-weight-bold text-right">Total Cash ({{ reportCurrencySelected }})</td>
                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[0].toFixed(4)) }}</td>
                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[1].toFixed(4)) }}</td>
                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[2].toFixed(4)) }}</td>
                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[3].toFixed(4)) }}</td>
                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[4].toFixed(4)) }}</td>
                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[5].toFixed(4)) }}</td>
                    <td class="table-secondary font-weight-bold text-right">{{ $t('portfolio.reporting_currency') }} : {{ reportCurrencySelected }}</td>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-actions>
          <!-- BONDS CURRENT -->
          <b-card-actions v-if="portfolioDatas.fields && (emptyAccounts != false || portfolioDatas.currentBonds.length > 0)" action-collapse :title="$t('portfolio.bonds_current')">
            <b-row>
              <b-col cols="12">
                <b-table
                  :items="portfolioDatas.currentBonds"
                  :fields="portfolioDatas.fields[1]"
                  :bordered="true"
                  table-variant="none"php 
                  :small="true"
                  :hover="true"
                  :striped="true"
                  :fixed="false"
                  headVariant="dark"
                  class="text-center compact_table"
                  :tbody-tr-class="rowClass"
                  responsive
                >
                  <template #cell(s0)="data">
                    <div class="text-left">
                      {{ data.item.AccountName }}
                    </div>
                  </template>
                  <template #cell(s1)="data">
                    <small
                      ><div class="text-left text-nowrap">{{ data.item.Underlying }}</div></small
                    >
                  </template>

                  <template #cell(s2)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT) }}
                    </div>
                  </template>

                  <template #cell(s3)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT1 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT1) }}
                    </div>
                  </template>
                  <template #cell(s4)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT2 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT2) }}
                    </div>
                  </template>
                  <template #cell(s5)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT3 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT3) }}
                    </div>
                  </template>

                  <template #cell(s6)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountF < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountF) }}
                    </div>
                  </template>

                  <template #cell(s7)="data">
                    <div class="text-right text-nowrap">
                      {{ data.item.SecurityCcy }}
                    </div>
                  </template>

                  <template #cell(s8)="data">
                    <div class="text-right text-nowrap" :class="[data.item.Price < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.Price) }}
                    </div>
                  </template>

                  <!-- <template #cell(s9)="data">
                    <div class="text-right text-nowrap" :class="[(data.item.InterestPrice * data.item.TotalAmountT1) / 100000 < 0 ? 'text-danger' : '']">
                      {{ data.item.SecurityCcy }} {{ moneyFixed((data.item.InterestPrice * data.item.TotalAmountT1) / 100000) }}
                    </div>
                  </template>

                  <template #cell(s10)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge class="mr-1" v-if="(data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000 >= 0" variant="success">
                        {{ moneyFixed((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) }}
                      </b-badge>
                      <b-badge class="mr-1" v-else variant="danger"> {{ moneyFixed((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) }} </b-badge>

                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template>

                  <template #cell(s11)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge class="mr-1" v-if="((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) / data.item.Exchange >= 0" variant="success">
                        {{ moneyFixed(((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) / data.item.Exchange) }}
                      </b-badge>
                      <b-badge class="mr-1" v-else variant="danger"> {{ moneyFixed(((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) / data.item.Exchange) }} </b-badge>
                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template> -->


                  <template #cell(s9)="data">
                    <div class="text-right text-nowrap" :class="[(data.item.InterestPrice * data.item.TotalAmountT1) < 0 ? 'text-danger' : '']">
                      {{ data.item.SecurityCcy }} {{ moneyFixed((data.item.InterestPrice * data.item.TotalAmountT1)) }}
                    </div>
                  </template>

                  <template #cell(s10)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge class="mr-1" v-if="(data.item.Price * data.item.TotalAmountT) + (data.item.InterestPrice * data.item.TotalAmountT1) >= 0" variant="success">
                        {{ moneyFixed(((data.item.Price * data.item.TotalAmountT) + (data.item.InterestPrice * data.item.TotalAmountT1)) /100) }}
                      </b-badge>
                      <b-badge class="mr-1" v-else variant="danger"> {{ moneyFixed((data.item.Price * data.item.TotalAmountT) + (data.item.InterestPrice * data.item.TotalAmountT1)) }} </b-badge>

                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template>

                  <template #cell(s11)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge class="mr-1" v-if="((data.item.Price * data.item.TotalAmountT) + (data.item.InterestPrice * data.item.TotalAmountT1)) / data.item.Exchange >= 0" variant="success">
                        {{ moneyFixed(((data.item.Price * data.item.TotalAmountT) + (data.item.InterestPrice * data.item.TotalAmountT1)) / data.item.Exchange /100) }}
                      </b-badge>
                      <b-badge class="mr-1" v-else variant="danger"> {{ moneyFixed(((data.item.Price * data.item.TotalAmountT) + (data.item.InterestPrice * data.item.TotalAmountT1)) / data.item.Exchange) }} </b-badge>
                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4)/100 : 0 }} </b-badge>
                    </div>
                  </template>


                  <template slot="bottom-row" v-if="portfolioDatas.currentBonds.length != 0">
                    <td class="table-secondary font-weight-bold text-right" v-if="multiReport == true"></td>
                    <td class="table-secondary font-weight-bold text-right" COLSPAN="8"></td>
                    <td class="table-secondary font-weight-bold text-right" COLSPAN="2">Total BONDS CURRENT : ( {{ reportCurrencySelected }} )</td>

                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[6].toFixed(4) /100)  }}</td>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-actions>
          <!-- BONDS CALLATERAL -->
          <b-card-actions v-if="portfolioDatas.fields && (emptyAccounts != false || portfolioDatas.collateralBonds.length > 0)" action-collapse :title="$t('portfolio.bonds_collateral')">
            <b-row>
              <b-col cols="12">
                <b-table
                  :items="portfolioDatas.collateralBonds"
                  :fields="portfolioDatas.fields[2]"
                  :bordered="true"
                  table-variant="none"
                  :small="true"
                  :hover="true"
                  :striped="true"
                  :fixed="false"
                  headVariant="dark"
                  class="text-center compact_table"
                  :tbody-tr-class="rowClass"
                  responsive
                >
                  <template #cell(s0)="data">
                    <div class="text-left">
                      {{ data.item.AccountName }}
                    </div>
                  </template>
                  <template #cell(s1)="data">
                    <small
                      ><div class="text-left text-nowrap">{{ data.item.Underlying }}</div></small
                    >
                  </template>

                  <template #cell(s2)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT) }}
                    </div>
                  </template>

                  <template #cell(s3)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT1 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT1) }}
                    </div>
                  </template>
                  <template #cell(s4)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT2 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT2) }}
                    </div>
                  </template>
                  <template #cell(s5)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT3 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT3) }}
                    </div>
                  </template>

                  <template #cell(s6)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountF < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountF) }}
                    </div>
                  </template>

                  <template #cell(s7)="data">
                    <div class="text-right text-nowrap">
                      {{ data.item.SecurityCcy }}
                    </div>
                  </template>

                  <template #cell(s8)="data">
                    <div class="text-right text-nowrap" :class="[data.item.Price < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.Price) }}
                    </div>
                  </template>

                  <template #cell(s9)="data">
                    <div class="text-right text-nowrap" :class="[(data.item.InterestPrice * data.item.TotalAmountT1) / 100000 < 0 ? 'text-danger' : '']">
                      {{ data.item.SecurityCcy }} {{ moneyFixed((data.item.InterestPrice * data.item.TotalAmountT1) / 100000) }}
                    </div>
                  </template>

                  <template #cell(s10)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge class="mr-1" v-if="(data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000 >= 0" variant="success">
                        {{ moneyFixed((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) }}
                      </b-badge>
                      <b-badge class="mr-1" v-else variant="danger"> {{ moneyFixed((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) }} </b-badge>

                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template>

                  <template #cell(s11)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge class="mr-1" v-if="((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) / data.item.Exchange >= 0" variant="success">
                        {{ moneyFixed(((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) / data.item.Exchange) }}
                      </b-badge>
                      <b-badge class="mr-1" v-else variant="danger"> {{ moneyFixed(((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) / data.item.Exchange) }} </b-badge>
                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template>

                  <template slot="bottom-row" v-if="portfolioDatas.collateralBonds.length != 0">
                    <td class="table-secondary font-weight-bold text-right" v-if="multiReport == true"></td>
                    <td class="table-secondary font-weight-bold text-right" COLSPAN="8"></td>
                    <td class="table-secondary font-weight-bold text-right" COLSPAN="2">Total BONDS COLLATERAL : ( {{ reportCurrencySelected }} )</td>

                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[7].toFixed(4)) }}</td>
                  </template>

                  <!--   <template #cell(s10)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge class="mr-1" variant="success"> {{ moneyFixed(data.item.Price * data.item.TotalAmountT) }} </b-badge>
                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template>

                  <template #cell(s11)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge class="mr-1" variant="success"> {{ moneyFixed(((data.item.Price * data.item.TotalAmountT) / 100 + (data.item.InterestPrice * data.item.TotalAmountT1) / 100000) / data.item.Exchange) }} </b-badge>
                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template>

                  <template slot="bottom-row" v-if="portfolioDatas.collateralBonds.length != 0">
                    <td class="table-secondary font-weight-bold text-right" v-if="multiReport == true"></td>
                    <td class="table-secondary font-weight-bold text-right" COLSPAN="8"></td>
                    <td class="table-secondary font-weight-bold text-right" COLSPAN="2">Total BONDS COLLATERAL : ( {{ reportCurrencySelected }} )</td>

                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[7].toFixed(4)) }}</td>
                  </template>


!-->
                </b-table>
              </b-col>
            </b-row>
          </b-card-actions>
          <!-- STOCKS CURRENT -->
          <b-card-actions v-if="portfolioDatas.fields && (emptyAccounts != false || portfolioDatas.currentStocks.length > 0)" action-collapse :title="$t('portfolio.stocks_current')">
            <b-row>
              <b-col cols="12">
                <b-table
                  :items="portfolioDatas.currentStocks"
                  :fields="portfolioDatas.fields[3]"
                  :bordered="true"
                  table-variant="none"
                  :small="true"
                  :hover="true"
                  :striped="true"
                  :fixed="false"
                  headVariant="dark"
                  class="text-center compact_table"
                  :tbody-tr-class="rowClass"
                  responsive
                >
                  <template #cell(s0)="data">
                    <div class="text-left">
                      {{ data.item.AccountName }}
                    </div>
                  </template>
                  <template #cell(s1)="data">
                    <small
                      ><div class="text-left text-nowrap">{{ data.item.Underlying }}</div></small
                    >
                  </template>

                  <template #cell(s2)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT) }}
                    </div>
                  </template>

                  <template #cell(s3)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT1 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT1) }}
                    </div>
                  </template>
                  <template #cell(s4)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT2 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT2) }}
                    </div>
                  </template>
                  <template #cell(s5)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT3 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT3) }}
                    </div>
                  </template>

                  <template #cell(s6)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountF < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountF) }}
                    </div>
                  </template>

                  <template #cell(s7)="data">
                    <div class="text-right text-nowrap">
                      {{ data.item.SecurityCcy }}
                    </div>
                  </template>

                  <template #cell(s8)="data">
                    <div class="text-right text-nowrap" :class="[data.item.Price < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.Price) }}
                    </div>
                  </template>

                  <template #cell(s9)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge class="mr-1" v-if="data.item.Price * data.item.TotalAmountT >= 0" variant="success"> {{ moneyFixed(data.item.Price * data.item.TotalAmountT) }} </b-badge>
                      <b-badge class="mr-1" v-else variant="danger"> {{ moneyFixed(data.item.Price * data.item.TotalAmountT) }} </b-badge>
                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template>

                  <template #cell(s10)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge class="mr-1" v-if="(data.item.Price * data.item.TotalAmountT) / data.item.Exchange >= 0" variant="success"> {{ moneyFixed((data.item.Price * data.item.TotalAmountT) / data.item.Exchange) }} </b-badge>
                      <b-badge class="mr-1" v-else variant="danger"> {{ moneyFixed((data.item.Price * data.item.TotalAmountT) / data.item.Exchange) }} </b-badge>
                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template>

                  <template slot="bottom-row" v-if="portfolioDatas.currentStocks.length != 0">
                    <td class="table-secondary font-weight-bold text-right" v-if="multiReport == true"></td>
                    <td class="table-secondary font-weight-bold text-right" COLSPAN="8"></td>
                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[8].toFixed(4)) }}</td>
                    <td class="table-secondary font-weight-bold text-right" COLSPAN="1">Total STOCKS CURRENT : ( {{ reportCurrencySelected }} )</td>

                    
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-actions>
          <!-- STOCKS COLLATERAL -->
          <b-card-actions v-if="portfolioDatas.fields && (emptyAccounts != false || portfolioDatas.collateralStocks.length > 0)" action-collapse :title="$t('portfolio.stocks_collateral')">
            <b-row>
              <b-col cols="12">
                <b-table
                  :items="portfolioDatas.collateralStocks"
                  :fields="portfolioDatas.fields[4]"
                  :bordered="true"
                  table-variant="none"
                  :small="true"
                  :hover="true"
                  :striped="true"
                  :fixed="false"
                  headVariant="dark"
                  class="text-center compact_table"
                  :tbody-tr-class="rowClass"
                  responsive
                >
                  <template #cell(s0)="data">
                    <div class="text-left">
                      {{ data.item.AccountName }}
                    </div>
                  </template>
                  <template #cell(s1)="data">
                    <small
                      ><div class="text-left text-nowrap">{{ data.item.Underlying }}</div></small
                    >
                  </template>

                  <template #cell(s2)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT) }}
                    </div>
                  </template>

                  <template #cell(s3)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT1 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT1) }}
                    </div>
                  </template>
                  <template #cell(s4)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT2 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT2) }}
                    </div>
                  </template>
                  <template #cell(s5)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountT3 < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountT3) }}
                    </div>
                  </template>

                  <template #cell(s6)="data">
                    <div class="text-right text-nowrap" :class="[data.item.TotalAmountF < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.TotalAmountF) }}
                    </div>
                  </template>

                  <template #cell(s7)="data">
                    <div class="text-right text-nowrap">
                      {{ data.item.SecurityCcy }}
                    </div>
                  </template>

                  <template #cell(s8)="data">
                    <div class="text-right text-nowrap" :class="[data.item.Price < 0 ? 'text-danger' : '']">
                      {{ moneyFixed(data.item.Price) }}
                    </div>
                  </template>

                  <template #cell(s9)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge class="mr-1" v-if="data.item.Price * data.item.TotalAmountT >= 0" variant="success"> {{ moneyFixed(data.item.Price * data.item.TotalAmountT) }} </b-badge>
                      <b-badge class="mr-1" v-else variant="danger"> {{ moneyFixed(data.item.Price * data.item.TotalAmountT) }} </b-badge>
                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ data.item.SecurityCcy }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template>

                  <template #cell(s10)="data">
                    <div class="text-right text-nowrap">
                      <b-badge class="mr-1" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge class="mr-1" v-if="(data.item.Price * data.item.TotalAmountT) / data.item.Exchange >= 0" variant="success"> {{ moneyFixed((data.item.Price * data.item.TotalAmountT) / data.item.Exchange) }} </b-badge>
                      <b-badge class="mr-1" v-else variant="danger"> {{ moneyFixed((data.item.Price * data.item.TotalAmountT) / data.item.Exchange) }} </b-badge>
                      <b-badge class="mr-1 " v-if="data.item.Exchange != data.item.Exchange" variant="dark"> {{ reportCurrencySelected }} </b-badge>
                      <b-badge v-if="data.item.Exchange != data.item.Exchange" variant="success"> {{ data.item.Exchange ? data.item.Exchange.toFixed(4) : 0 }} </b-badge>
                    </div>
                  </template>

                  <template slot="bottom-row" v-if="portfolioDatas.collateralStocks.length != 0">
                    <td class="table-secondary font-weight-bold text-right" v-if="multiReport == true"></td>
                    <td class="table-secondary font-weight-bold text-right" COLSPAN="7"></td>
                    <td class="table-secondary font-weight-bold text-right" COLSPAN="2">Total STOCKS COLLATERAL : ( {{ reportCurrencySelected }} )</td>

                    <td class="table-secondary font-weight-bold text-right">{{ reportCurrencySelected }} {{ moneyFixed(portfolioDatas.totalCash[9].toFixed(4)) }}</td>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
      <template #overlay v-if="loading">
          <div
            class="text-center d-flex justify-content-center align-items-start mt-3"
          >
          
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />

              {{
                new Date(valuationDate).getFullYear() < 
                new Date().getFullYear() ? 

                $t(
                  "general_text.long_report_preparing"
                ) : $t(
                  "general_text.report_preparing"
                ) 
              }}
            </p>
          </div>
        </template>
    </b-overlay>
  </div>
</template>

<script>
import usePortfolioList from './usePortfolioList';
import { BFormInput, BFormGroup, BForm, BButton, BAlert, BFormCheckbox, VBTooltip, BTable, BOverlay, BBadge, BPagination, BFormSelect, BFormDatepicker, BCardBody, BCardHeader, BCardText, BCard, BRow, BCol } from 'bootstrap-vue';
import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import portfolioStoreModule from './portfolioStoreModule';
import SettingsCard from '@core/components/statistics-cards/SettingsCard.vue';
import vSelect from 'vue-select';

import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import StatisticCardStatement from '@core/components/statistics-cards/StatisticCardStatement.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import moment from 'moment';
import jsPDF from 'jspdf';
import VueHtml2pdf from 'vue-html2pdf';
import Ripple from 'vue-ripple-directive';
import "animate.css";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BCardActions,
    BButton,
    BTable,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardHeader,
    VueHtml2pdf,
    jsPDF,
    vSelect,
    SettingsCard,
    BFormDatepicker,
    BFormCheckbox,
    BAlert,
    BFormSelect,
    BPagination,
    BBadge,
    BCardCode,

    StatisticCardStatement,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  setup() {
    const PORTFOLIO_APP_STORE_MODULE_NAME = 'portfolio';
    // Register module
    if (!store.hasModule(PORTFOLIO_APP_STORE_MODULE_NAME)) store.registerModule(PORTFOLIO_APP_STORE_MODULE_NAME, portfolioStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PORTFOLIO_APP_STORE_MODULE_NAME)) store.unregisterModule(PORTFOLIO_APP_STORE_MODULE_NAME);
    });

    const { costCenterSelected, futureValuation, multiReport, reportCurrencySelected, tradeValuation, valuationDate, portfolioDatas, accountNameSelected, accountGroupSelected, accountNamesTitle, fetchHoldingsMultipleAccounts, loading, showAlertMessage } = usePortfolioList();

    return {
      fetchHoldingsMultipleAccounts,
      valuationDate,
      accountNameSelected,
      accountGroupSelected,
      multiReport,
      futureValuation,
      portfolioDatas,
      loading,
      showAlertMessage,
      tradeValuation,
      accountNamesTitle,
      reportCurrencySelected,
      costCenterSelected,

      // Filter
    };
  },

  data() {
    var currencys = [];
    var emptyAccounts = false;
    var currencyLoading = false;
    var accountGroups = [];
    var accountGroupName = null;

    return {
      optAccountNames: [],
      accountGroups,
      currencys,
      emptyAccounts,
      currencyLoading,
      downloadPDFloading:false,
      accountGroupName,
      groupNameState: null,
      optCostCenters : [],
    };
  },

  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },

  created() {
    this.getAccountNames();
    this.getAccGroups();
  },

  methods: {
    getAccountNames(data) {
      store.dispatch('portfolio/getAccountNames',).then((res) => {
        this.optAccountNames = res.data.resData;
        this.optCostCenters = res.data.costCenters;

      }).catch((error) => {
        console.log(error);
      });
    },

    getAccGroups(data) {
      store.dispatch('portfolio/getAccGroups', data).then((res) => {
        this.accountGroups = res.data;
      }).catch((error) => {
        console.log(error);
      });
    },

    getCurrencys(data) {
      this.currencyLoading = true;
      store.dispatch('portfolio/selectLatestAvailableSecurityPrices', data).then((res) => {
        this.currencys = res.data;
        this.currencyLoading = false;
      }).catch((error) => {
        console.log(error);
      });;
    },

    downloadPdf(data) {
      //this.loading = true;
      data['date'] = this.valuationDate;
      data['accountName'] = this.accountNameSelected;
      data['currency'] = this.reportCurrencySelected;
      data['multireport'] = this.multiReport;
      data['emptyAccounts'] = this.emptyAccounts;

      //  data[0]['end'] = this.endDate;
      this.downloadPDFloading = true
      store.dispatch('portfolio/downloadPdfGet', data).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.valuationDate + ' Report.pdf');
        document.body.appendChild(link);
        link.click();
        //this.loading = false;
        this.downloadPDFloading = false
      }).catch((error) => {
        console.log(error);
      });
    },

    updateGroup() {
      this.accountGroupName = this.accountGroupSelected.AccountGroup;
    },

    handleOk(evt) {
      // Prevent modal from closing
      evt.preventDefault();
      const valid = this.$refs.form.checkValidity();
      this.groupNameState = valid;
      const name = [];

      this.accountNameSelected.forEach((element) => {
        name.push(element.AccountName);
      });

      const data = {
        accountGroupName: this.accountGroupName,
        accountNames: name,
      };
      if (valid) {
        store.dispatch('portfolio/postAccountGroups', data).then((res) => {
          this.accountGroupName = '';
          this.$refs.form = null;
          this.groupNameState = '';
          this.$nextTick(() => {
            this.$bvModal.hide('modal-addgroup');
            this.$bvModal.hide('modal-updategroup');
          });
        }).catch((error) => {
        console.log(error);
      });
      }
    },

    handleSubmit() {
      // console.log('ok');
      /* console.log(JSON.stringify(this.form));
      this.clearForm();
      this.$refs.modal.hide();*/
    },

    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0 || weekday === 6;
    },

    shortDateValue(dateValue) {
      if (dateValue != '') {
        return moment(dateValue).format('DD-MMM-YY');
      } else {
        return null;
      }
    },

    rowClass(item, type) {
      const colorClass = 'table-info';
      if (!item || type !== 'row') {
        return;
      }

      // eslint-disable-next-line consistent-return
      if (item.Reversed === '1') {
        return colorClass;
      }
    },

    moneyFixed(value) {
      if (value != null && value != '') {
        let val = (parseFloat(value) / 1).toFixed(2);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        return '0.00';
      }
    },
  },

  watch: {
    valuationDate: function(val) {
      this.getCurrencys({
        date: val,
      });
    },
  },

  
};
</script>

<style scoped>
.text-danger{
  font-size: 0.7rem;
  font-weight: bold;
}

.font-weight-bold {
  font-weight: 800 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

// multiple vue select input
.v-select:not(.vs--single) .vs__selected {
  font-size: 0.6rem !important;
}

</style>


<!-- compact design scss code -->

<style lang="scss" scoped>

@import "~/src/assets/scss/compact-design.scss";

.card::v-deep .card-body {
    padding: 0.8rem 0.7rem !important;
  
    .card-title {
        font-weight: 500 !important;
        font-size: 1.285rem !important;
        margin-bottom: 0.6rem !important;
        text-decoration: none !important;
    }
  }

// date picker
.compact_date_picker::v-deep .form-control {
  padding-left: 0.1rem !important;
  padding-top: 0.3rem !important;
}

// multiple vue select input
.compact_form_vue-multi-select::v-deep .vs__selected {
    position: relative !important;
}

//

</style>
