import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import moment from "moment";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useStatement() {
  // Use toast
  const toast = useToast();
  const accountNameSelected = ref([]);
  const accountGroupSelected = ref(null);
  const reportCurrencySelected = ref("USD");
  const currencys = ref([]);
  const portfolioDatas = ref([]);

  const valuationDate = ref(null);
  const tradeValuation = ref(false);
  const multiReport = ref(false);
  const loading = ref(false);
  const showAlertMessage = ref(true);
  const futureValuation = ref(false);
  const accountNamesTitle = ref("");
  const costCenterSelected = ref(null);

  const refetchData = () => {
    fetchHoldingsMultipleAccounts();
  };

  watch(
    [
      accountNameSelected,
      reportCurrencySelected,
      valuationDate,
      tradeValuation,
      futureValuation,
      costCenterSelected,
    ],
    () => {
      accountNamesTitle.value = "";
      accountNameSelected.value.forEach((element) => {
        accountNamesTitle.value =
          accountNamesTitle.value + element.AccountName + " | ";
      });

      if (accountNameSelected.value.length > 1) {
        multiReport.value = true;
      } else {
        multiReport.value = false;
      }

      if (
        valuationDate.value != null &&
        accountNameSelected.value.length > 0 &&
        reportCurrencySelected.value != null
      ) {
        portfolioDatas.value = [];
        refetchData();
      }
    }
  );

  watch([accountNameSelected], () => {
    portfolioDatas.value = [];
    costCenterSelected.value = null

  });

  watch([accountGroupSelected], () => {
    if (accountGroupSelected.value != null) {
      var selectData = {
        accountGroup: accountGroupSelected.value.AccountGroup.trim(),
      };
      accountNameSelected.value = [];
      store
        .dispatch("portfolio/getAccountGroups", selectData)
        .then((response) => {
          response.forEach((element) => {
            accountNameSelected.value.push({
              AccountName: element.AccountName,
              AccountNumber: element.AccountNumber,
            });
          });
        });
    } else {
      accountNameSelected.value = [];
    }
  });

  const fetchHoldingsMultipleAccounts = (ctx, callback) => {
    loading.value = true;
    showAlertMessage.value = false;
    store
      .dispatch("portfolio/getHoldingsMultipleAccounts", {
        accounts: accountNameSelected.value,
        //   accountNumber: accountNameSelected.value.AccountNumber,
        date: valuationDate.value,
        base: reportCurrencySelected.value,
        isValueDate: tradeValuation.value,
        futureValuation: futureValuation.value,
        multiReport: multiReport.value,
        costCenterSelected:costCenterSelected.value
      })
      .then((response) => {
        //  callback(response.data);

        const fields1 = [];
        const fields2 = [];
        const fields3 = [];
        const fields4 = [];
        const fields5 = [];

        response.data.fields[0].forEach((element, index) => {
          if (multiReport.value == true || index > 0) {
            if (
              index == 2 ||
              index == 3 ||
              index == 4 ||
              index == 5 ||
              index == 6
            ) {
              fields1.push({
                key: "s" + index,
                label: moment(element).format("DD-MMM-YY"),
              });
            } else if (index == 8) {
              if (futureValuation.value == false) {
                fields1.push({
                  key: "s" + index,
                  label:
                    " Exchange Rates " + moment(element).format("DD-MMM-YY"),
                });
              } else {
                fields1.push({
                  key: "s" + index,
                  label: " Exchange Rates Future Price",
                });
              }
            } else {
              fields1.push({ key: "s" + index, label: element });
            }
          }
        });

        response.data.fields[1].forEach((element, index) => {
          if (multiReport.value == true || index > 0) {
            if (index == 2 || index == 3 || index == 4 || index == 5) {
              fields2.push({
                key: "s" + index,
                label: moment(element).format("DD-MMM-YY"),
              });
            } else if (index == 8) {
              if (futureValuation.value == false) {
                fields2.push({
                  key: "s" + index,
                  label: moment(element).format("DD-MMM-YY") + " Price",
                });
              } else {
                fields2.push({ key: "s" + index, label: "Future Price" });
              }
            } else if (index == 10) {
              if (futureValuation.value == false) {
                fields2.push({
                  key: "s" + index,
                  label:
                    "VALUE (ON " + moment(element).format("DD-MMM-YY") + ")",
                });
              } else {
                fields2.push({ key: "s" + index, label: "VALUE (in Future)" });
              }
            } else {
              fields2.push({ key: "s" + index, label: element });
            }
          }
        });
        response.data.fields[2].forEach((element, index) => {
          if (multiReport.value == true || index > 0) {
            if (index == 2 || index == 3 || index == 4 || index == 5) {
              fields3.push({
                key: "s" + index,
                label: moment(element).format("DD-MMM-YY"),
              });
            } else if (index == 8) {
              if (futureValuation.value == false) {
                fields3.push({
                  key: "s" + index,
                  label: moment(element).format("DD-MMM-YY") + " Price",
                });
              } else {
                fields3.push({ key: "s" + index, label: "Future Price" });
              }
            } else if (index == 10) {
              if (futureValuation.value == false) {
                fields3.push({
                  key: "s" + index,
                  label:
                    "VALUE (ON " + moment(element).format("DD-MMM-YY") + ")",
                });
              } else {
                fields3.push({ key: "s" + index, label: "VALUE (in Future)" });
              }
            } else {
              fields3.push({ key: "s" + index, label: element });
            }
          }
        });
        response.data.fields[3].forEach((element, index) => {
          if (multiReport.value == true || index > 0) {
            if (index == 2 || index == 3 || index == 4 || index == 5) {
              fields4.push({
                key: "s" + index,
                label: moment(element).format("DD-MMM-YY"),
              });
            } else if (index == 8) {
              if (futureValuation.value == false) {
                fields4.push({
                  key: "s" + index,
                  label: moment(element).format("DD-MMM-YY") + " Price",
                });
              } else {
                fields4.push({ key: "s" + index, label: "Future Price" });
              }
            } else if (index == 9) {
              if (futureValuation.value == false) {
                fields4.push({
                  key: "s" + index,
                  label:
                    "VALUE (ON " + moment(element).format("DD-MMM-YY") + ")",
                });
              } else {
                fields4.push({ key: "s" + index, label: "VALUE (IN FUTURE)" });
              }
            } else {
              fields4.push({ key: "s" + index, label: element });
            }
          }
        });
        response.data.fields[4].forEach((element, index) => {
          if (multiReport.value == true || index > 0) {
            if (index == 2 || index == 3 || index == 4 || index == 5) {
              fields5.push({
                key: "s" + index,
                label: moment(element).format("DD-MMM-YY"),
              });
            } else if (index == 8) {
              if (futureValuation.value == false) {
                fields5.push({
                  key: "s" + index,
                  label: moment(element).format("DD-MMM-YY") + " Price",
                });
              } else {
                fields5.push({ key: "s" + index, label: "Future Price" });
              }
            } else if (index == 9) {
              if (futureValuation.value == false) {
                fields5.push({
                  key: "s" + index,
                  label:
                    "VALUE (ON " + moment(element).format("DD-MMM-YY") + ")",
                });
              } else {
                fields5.push({ key: "s" + index, label: "VALUE (IN FUTURE)" });
              }
            } else {
              fields5.push({ key: "s" + index, label: element });
            }
          }
        });

        response.data.fields[0] = fields1;
        response.data.fields[1] = fields2;
        response.data.fields[2] = fields3;
        response.data.fields[3] = fields4;
        response.data.fields[4] = fields5;

        if (
          response.data.currentAccounts &&
          response.data.currentAccounts.length == 0
        ) {
          showAlertMessage.value = true;
        }

        portfolioDatas.value =
          response.data.currentAccounts.length > 0 ? response.data : [];

        loading.value = false;
      })
      .catch((e) => {
        loading.value = false;
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching  ",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchHoldingsMultipleAccounts,
    accountNameSelected,
    accountGroupSelected,
    currencys,
    portfolioDatas,
    loading,
    showAlertMessage,
    multiReport,
    valuationDate,
    tradeValuation,
    futureValuation,
    reportCurrencySelected,
    accountNamesTitle,
    refetchData,
    costCenterSelected,
  };
}
